import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/system/user",
    // 层级选择器
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
    // 配置用户角色
    roleSelect(query: any) {
        return requests.post(this.modulesUrl + "/roleSelect", query);
    },
    // 保存用户角色
    roleSelectSave(query: any) {
        return requests.post(this.modulesUrl + "/roleSelectSave", query);
    },
    // 获取用户钉钉ID
    dataUpUserDdInfo(query: any) {
        return requests.post(this.modulesUrl + "/dataUpUserDdInfo", query);
    },
};
